import useActivity from 'src/providers/hooks/useActivity';
import useProfile from 'src/providers/hooks/useProfile';
import { DEFAULT_READ_TIME } from 'src/utils/constants';
import { PausableTimeout, selectHighestPriorityIdea } from 'src/utils/global';
import { Router } from 'next/router';
import React, {
  createContext,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Idea } from 'types/models';
import { getCollectionIdOnSourcePage } from 'utils/collection.utils';
import useNsiActivity from '../hooks/useNsiActivity';
import useAuth from '../hooks/useAuth';

interface OwnProps {
  children: any;
}

type IntersectionObserverContextType = {
  setInsights: (insights: Idea[]) => void;
};

export const IntersectionObserverContext =
  createContext<IntersectionObserverContextType>({
    setInsights: () => {
      return;
    },
  });

const IntersectionObserverProvider: React.FC<OwnProps> = ({
  children,
}: OwnProps) => {
  const markAsReadQueue = useRef<Idea[]>([] as Idea[]) as MutableRefObject<
    Idea[]
  >;
  const markAsReadTimeout = useRef<PausableTimeout>(new PausableTimeout());
  const currentHighPriorityInsightId = useRef<number | null>(
    null as number | null,
  ) as MutableRefObject<number | null>;
  const isWindowBlured = useRef(false);
  const ideas = useRef([] as Idea[]);
  const { activity, activityDispatch } = useActivity();
  const { nsiActivityDispatch, reads: nsiReads } = useNsiActivity();
  const { profile } = useProfile();
  const { isLoggedIn } = useAuth();

  const intersectionObserverOptions = {
    threshold: [0.2, 0.6],
    rootMargin: '0px',
  };

  const onWindowBlur = () => {
    isWindowBlured.current = true;
    markAsReadTimeout.current.pause();
  };
  const onWindowFocus = () => {
    isWindowBlured.current = false;
    markAsReadTimeout.current.resume();
  };

  const evaluateMarkAsReadQueue = useCallback(() => {
    const dispatchRead = () => {
      const highPriorityId = currentHighPriorityInsightId.current;

      // We have profile?.department !== null -- to avoid reads from incrementing while you are in setup
      // Department is null only if you create a new account that has not completed the setup
      // Legacy accounts which didn't choose a department the value for the department is an empty string, not null
      // So we have all the cases covered
      if (highPriorityId && profile?.department !== null) {
        const callback = (id: number) => {
          if (isLoggedIn) {
            activityDispatch({
              type: 'increment-read',
              payload: {
                ideaId: id,
                readingGoal: profile?.dailyReadingGoal ?? 0,
                collectionId: getCollectionIdOnSourcePage(),
              },
            });
          } else {
            nsiActivityDispatch({ type: 'read-idea', payload: { ideaId: id } });
          }
          markAsReadQueue.current = markAsReadQueue.current.filter(
            val => val.id !== currentHighPriorityInsightId.current,
          );
          currentHighPriorityInsightId.current = null;
          evaluateMarkAsReadQueue();
        };
        const idea = ideas.current.find(
          val => val.id === currentHighPriorityInsightId.current,
        );

        //Time to read can come as string from the api so we make the following assertion to decide the delay
        const delay =
          (idea?.timeToRead &&
          Number.parseFloat(idea.timeToRead.toString()) !== 0
            ? Number.parseFloat(idea.timeToRead.toString())
            : DEFAULT_READ_TIME) * 1000;
        markAsReadTimeout.current.start(() => callback(highPriorityId), delay);
        // Pause the timeout if it started with the window blurred
        if (isWindowBlured.current) markAsReadTimeout.current.pause();
      }
    };

    const priorityIdea = selectHighestPriorityIdea(markAsReadQueue.current);
    if (
      priorityIdea &&
      (!currentHighPriorityInsightId.current ||
        currentHighPriorityInsightId.current !== priorityIdea.id)
    ) {
      markAsReadTimeout.current.stop();
      currentHighPriorityInsightId.current = priorityIdea.id;
      dispatchRead();
    }
  }, [isLoggedIn, profile?.department]);

  const intersectionObserverCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      for (const entry of entries) {
        //The id is `idea-<id>`
        const ideaId = Number.parseInt(entry.target.id.substring(5));
        if (
          !activity?.sessionReads?.has(ideaId) &&
          !nsiReads.sessionReads.has(ideaId)
        ) {
          const idea = ideas.current?.find(val => val.id === ideaId);
          if (idea) {
            const insightIndex = markAsReadQueue.current.findIndex(
              val => val.id === ideaId,
            );
            if (entry.intersectionRatio >= 0.5) {
              if (insightIndex === -1) {
                // Check if you need to fetch images for ideas

                markAsReadQueue.current.push(idea);
                evaluateMarkAsReadQueue();
              }
            } else if (insightIndex !== -1 && !entry.isIntersecting) {
              markAsReadQueue.current = markAsReadQueue.current.filter(
                val => val.id !== ideaId,
              );
              evaluateMarkAsReadQueue();
            }
          }
        }
      }
    },
    [activity?.sessionReads, nsiReads.sessionReads, evaluateMarkAsReadQueue],
  );

  const setInsights = useCallback((val: Idea[]) => {
    //Update the IOContext in a timeout to give the UI time to update and have elements with the class of insight on screen
    setTimeout(() => {
      //Don't let ideas without an id get added
      ideas.current.push(
        ...val.filter(
          idea =>
            idea.id !== -1 && !ideas.current.find(val => val.id === idea.id),
        ),
      );

      //The ideas change so register the dom elements to the IO
      intersectionObserverRef.current?.disconnect();
      registerToIntersectionObserver();

      //Cleanup the rest of the state

      currentHighPriorityInsightId.current = null;
      markAsReadQueue.current = [];
    }, 1000);
  }, []);

  //Clean up the ideas list
  useEffect(() => {
    const callback = () => {
      ideas.current = [];
    };
    Router.events.on('routeChangeStart', callback);
    return () => {
      Router.events.off('routeChangeStart', callback);
    };
  }, []);

  useEffect(() => {
    intersectionObserverRef.current = new IntersectionObserver(
      intersectionObserverCallback,
      intersectionObserverOptions,
    );
    registerToIntersectionObserver();
    return () => {
      markAsReadTimeout.current.stop();
      intersectionObserverRef.current?.disconnect();
      currentHighPriorityInsightId.current = null;
      markAsReadQueue.current = [];
    };
  }, [intersectionObserverCallback]);

  useEffect(() => {
    window.addEventListener('blur', onWindowBlur);
    window.addEventListener('focus', onWindowFocus);
    return () => {
      window.removeEventListener('blur', onWindowBlur);
      window.removeEventListener('focus', onWindowFocus);
    };
  }, []);

  const intersectionObserverRef = useRef<IntersectionObserver | null>(
    null,
  ) as MutableRefObject<IntersectionObserver | null>;

  const registerToIntersectionObserver = () => {
    markAsReadTimeout.current.stop();
    intersectionObserverRef.current?.disconnect();
    const elements = document.querySelectorAll('.insight');
    elements.forEach(element =>
      intersectionObserverRef.current?.observe(element),
    );
  };

  return (
    <IntersectionObserverContext.Provider value={{ setInsights }}>
      {children}
    </IntersectionObserverContext.Provider>
  );
};

export default IntersectionObserverProvider;
